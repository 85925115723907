.social-icons-box-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  background: var(--light-mode-primary-bg);
}

.dark-mode .social-icons-box-container {
  background: var(--dark-mode-primary-bg);
}

.social-icon-wrapper:hover {
  cursor: pointer;
  background: var(--primary-color);
}

.social-icon-wrapper {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid rgba(var(--primary-color-rgb), 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1440px) {
  .social-icon-wrapper .icon {
    width: 1rem; /* 16px */
  }
}

@media screen and (max-width: 1200px) {
  .social-icons-box-container {
    flex-direction: column;
    gap: 0.5rem; /* 8px */
  }
}
