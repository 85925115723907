.price-tracker-table-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px 30px 30px;
  background-color: #fff;
  cursor: default;
  gap: 1.25rem; /* 20px */
}

.price-tracker-table-container .table-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background: var(--light-mode-main-bg);
  width: calc(100% - 10px);
  border-radius: 16px;
  padding: 20px;
}

.dark-mode .table-heading-container {
  background-color: var(--dark-mode-main-bg);
}

.price-tracker-table-container .table-heading-container .table-heading.sembol {
  text-align: left;
}

.price-tracker-table-container .table-heading-container .table-heading {
  width: 25%;
  text-align: center;
  color: #435f75;
  font-size: 0.875rem;
}

.dark-mode .price-tracker-table-container .table-heading-container .table-heading {
  color: var(--dark-mode-sub-text);
}

.price-tracker-table-container .table-heading-container .table-heading.price,
.price-tracker-table-container .table-body-container .table-cell.price {
  width: 12.5%;
  font-size: 0.875rem; /* 14px */
  text-align: left;
}
.price-tracker-table-container .table-body-container {
  border-radius: 16px;
  width: 100%;
  padding-right: 10px;
  overflow-y: auto;
  max-height: 50vh;
  scrollbar-width: thin;
  scrollbar-color: #435f75 #ebebeb;
}

.dark-mode .table-body-container {
  color: var(--dark-mode-sub-text);
  scrollbar-color: var(--dark-mode-primary-bg);
}
/* Chrome, Edge, and Safari */
.price-tracker-table-container .table-body-container::-webkit-scrollbar {
  width: 5px;
}

.price-tracker-table-container .table-body-container::-webkit-scrollbar-track {
  background: #ebebeb;
}

.price-tracker-table-container .table-body-container::-webkit-scrollbar-thumb {
  background-color: #435f75;
  border-radius: 100px;
  border: 0;
}

.price-tracker-table-container .table-body-container .table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background: var(--light-mode-main-bg);
  width: 100%;
  border-radius: 16px;
  padding: 20px;
}

.dark-mode .price-tracker-table-container .table-body-container .table-row {
  background-color: var(--dark-mode-main-bg);
}

.price-tracker-table-container .table-body-container .table-row:nth-child(2n) {
  background: var(--light-mode-primary-bg);
}

.dark-mode
  .price-tracker-table-container
  .table-body-container
  .table-row:nth-child(2n) {
  background-color: var(--dark-mode-primary-bg);
}

.price-tracker-table-container .table-body-container .table-cell {
  width: 25%;
  text-align: center;
  font-size: 1.125rem;
}

.price-tracker-table-container .table-body-container .table-cell.sembol {
  text-align: left;
  color: #2c2a2a;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dark-mode .price-tracker-table-container .table-body-container .table-cell.sembol {
  color: var(--dark-mode-main-text);
}

.table-cell.sembol .icon {
  width: 15px;
}

.price-tracker-table-container .table-body-container .table-cell.time {
  color: #435f75;
  font-size: 0.825rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-mode .price-tracker-table-container .table-body-container .table-cell.time {
  color: var(--dark-mode-sub-text);
}

.price-tracker-table-container .table-body-container .table-cell.time .clock-icon {
  height: 1rem;
  margin-right: 8px;
}

.table-row .table-cell.price .figure.highlighted {
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 700;
  width: max-content;
  padding: 5px;
  border-radius: 4px;
}

.table-row .table-cell.price {
  font-weight: 600;
}

.table-row .table-cell.price.doviz {
  font-size: 1.125rem;
  width: 25%;
  text-align: center;
}

.table-row .table-cell.sembol .icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.table-row .table-cell.sembol .icons .currency-icon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

.table-cell.doviz .figure {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.doviz .yellow {
  color: var(--primary-color);
}

.doviz .red {
  color: rgb(var(--decrease-color-rgb));
}

.doviz .green {
  color: rgb(var(--increase-color-rgb));
}

.doviz .up-arrow {
  width: 0;
  height: 0;
  border-left: 0.375rem solid transparent; /* 7px */
  border-right: 0.375rem solid transparent;
  border-bottom: 0.375rem solid rgb(var(--increase-color-rgb));
}

.doviz .down-arrow {
  width: 0;
  height: 0;
  border-left: 0.375rem solid transparent; /* 7px */
  border-right: 0.375rem solid transparent;
  border-top: 0.375rem solid rgb(var(--decrease-color-rgb));
}

.table-row .table-cell.price .figure.highlighted.red {
  background: rgba(var(--decrease-color-rgb), 0.15);
  color: rgb(var(--decrease-color-rgb));
}

.table-row .table-cell.price .figure.highlighted.green {
  background: rgba(var(--increase-color-rgb), 0.15);
  color: rgb(var(--increase-color-rgb));
}

@media screen and (max-width: 1600px) {
  .table-body-container .table-cell.sembol {
    font-size: 0.875rem; /* 14px */
  }

  .price-tracker-table-container .table-body-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .price-tracker-table-container .table-body-container::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 1500px) {
  .table-body-container .table-cell.sembol,
  .table-body-container {
    font-size: 0.75rem; /* 12px */
  }
}
@media screen and (max-width: 600px) {
  .table-row p,
  .table-body-container .table-cell.time,
  .table-heading-container .table-heading.sembol,
  .price-tracker-table-container .table-heading-container .table-heading.price,
  .price-tracker-table-container .table-body-container .table-cell.sembol {
    font-size: 0.8rem;
  }

  .table-heading-container .table-heading:nth-child(4),
  .table-heading-container .table-heading:nth-child(6) {
    font-size: 0.75rem;
    width: 40%;
    text-align: end;
  }

  .price-tracker-table-container .table-body-container .table-cell.price {
    text-align: center;
    padding: 0.5rem;
    width: auto;
  }
  .table-row .table-cell.sembol .icons .currency-icon,
  .table-row .table-cell.sembol .icon {
    display: none;
  }
}

@media screen and (min-width: 430px) and (max-width: 1000px) {
  .table-row p,
  .table-body-container .table-cell.time,
  .table-heading-container .table-heading.sembol,
  .price-tracker-table-container .table-heading-container .table-heading.price,
  .price-tracker-table-container .table-body-container .table-cell.sembol {
    font-size: 0.8rem;
  }

  .table-heading-container .table-heading:nth-child(4),
  .table-heading-container .table-heading:nth-child(6) {
    font-size: 0.75rem;
    width: 40%;
    text-align: end;
  }

  .price-tracker-table-container .table-body-container .table-cell.price {
    text-align: center;
    padding: 0.5rem;
    width: auto;
  }
}

@media screen and (max-width: 430px) {
  .price-tracker-table-container,
  .price-trackers-wrapper > div:nth-child(2n) .table-row {
    padding: 0.5rem;
  }

  .price-tracker-table-container .table-body-container .table-cell.price {
    text-align: center;
    padding: 0;
    width: auto;
  }

  .price-tracker-table-container .table-heading-container,
  .price-tracker-table-container .table-body-container .table-row {
    padding: 0.675rem;
  }

  .table-row .table-cell.sembol .icons .currency-icon,
  .table-row .table-cell.sembol .icon,
  .table-heading-container .table-heading:nth-child(6),
  .price-trackers-wrapper > div:nth-child(1n) .table-heading:nth-child(4),
  .price-trackers-wrapper > div:nth-child(1n) .table-cell.time,
  .price-trackers-wrapper > div:nth-child(2n) .table-heading.sembol,
  .price-trackers-wrapper > div:nth-child(2n) .table-cell.sembol,
  .price-trackers-wrapper > div:nth-child(2n) .table-cell.time {
    display: none;
  }

  .table-row p,
  .table-body-container .table-cell.time,
  .table-heading-container .table-heading.sembol,
  .price-tracker-table-container .table-heading-container .table-heading.price,
  .price-tracker-table-container .table-body-container .table-cell.sembol {
    font-size: 0.75rem;
  }

  .table-heading-container .table-heading:nth-child(4),
  .table-heading-container .table-heading:nth-child(6) {
    font-size: 0.75rem;
    width: 40%;
    text-align: end;
  }

  .table-body-container .table-cell.time .clock-icon {
    width: 0.75rem;
  }
}
