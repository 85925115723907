.footer-container {
  width: 100%;
  padding: 32px;
  border-radius: 16px;
  color: var(--light-mode-main-text);
  background-color: var(--light-mode-primary-bg);
  max-width: 1920px;
}

.dark-mode .footer-container {
  color: var(--dark-mode-main-text);
  background-color: var(--dark-mode-primary-bg);
}

.contact-info-wrapper {
  display: flex;
}

.contact-info-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.advert-mapbox-section-wrapper {
  display: flex;
  align-items: center;
  gap: 32px;
  height: 180px;
  margin-top: 85px;
}

.advert-mapbox-section-wrapper .mapbox-wrapper {
  width: 40%;
}

.advert-mapbox-section-wrapper .mobile-app-links-container {
  width: 60%;
  height: 100%;
  padding: 45px 32px;
  border-radius: 16px;
  background-color: var(--light-mode-secondary-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.dark-mode .advert-mapbox-section-wrapper .mobile-app-links-container {
  background-color: var(--dark-mode-main-bg);
}

.advert-mapbox-section-wrapper
  .mobile-app-links-container
  .description-text
  .title-text {
  font-size: 1.125rem;
  font-weight: 400;
}

.advert-mapbox-section-wrapper
  .mobile-app-links-container
  .description-text
  .title-text.semi-bold {
  font-weight: 600;
}

.advert-mapbox-section-wrapper
  .mobile-app-links-container
  .description-text
  .subtitle-text {
  margin-top: 16px;
  font-size: 0.825rem;
  color: var(--light-mode-sub-text);
}

.dark-mode
  .advert-mapbox-section-wrapper
  .mobile-app-links-container
  .description-text
  .subtitle-text {
  color: var(--dark-mode-sub-text);
}

.advert-mapbox-section-wrapper
  .mobile-app-links-container
  .description-text
  .subtitle-text
  .highlighted {
  color: var(--primary-color);
}

.download-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
}

.download-buttons-container a {
  text-decoration: none;
  color: var(--dark-mode-main-text);
}

.download-buttons-container .download-button {
  height: 100%;
  min-width: 160px;
  border-radius: 100px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 18px;
  color: var(--dark-mode-main-text);
  font-size: 1.125rem;
  cursor: pointer;
}

.download-buttons-container .download-button:hover {
  filter: brightness(1.1);
}

.download-buttons-container .download-button .download-app-text {
  font-size: 0.625rem;
}

.download-buttons-container .download-button .download-app-text.semi-bold {
  font-weight: 600;
  font-size: 0.825rem;
}

.advert-mapbox-section-wrapper .footer-mobile-app-image-container {
  transform: translateY(-20px);
}

.footer-contact-detail .contact-detail-icon {
  border: 0.5px solid rgba(var(--light-mode-sub-text-rgb), 0.2);
  border-radius: 100px;
  padding: 16px;
}

.dark-mode .footer-contact-detail .contact-detail-icon {
  border: 0.5px solid rgba(var(--dark-mode-sub-text-rgb), 0.2);
}

.footer-logo {
  width: 10.8125rem;
}

.footer-contact-detail {
  display: flex;
  align-items: center;
  gap: 16px;
}

.footer-container .copyright-section-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 32px 0px;
}

.copyright-section-wrapper .copyright-section-text {
  font-size: 0.875rem;
}

.copyright-section-wrapper .copyright-section-text.highlighted {
  color: var(--primary-color);
}
.footer-contact-detail .contact-detail-text {
  font-size: 1rem;
}

@media screen and (max-width: 1600px) {
  .footer-container
    .advert-mapbox-section-wrapper
    .mobile-app-links-container
    .description-text
    .title-text {
    font-size: 0.75rem; /* 12px */
  }
}

@media screen and (max-width: 1440px) {
  .footer-container .footer-ozak-logo {
    width: 7rem; /* 112px */
  }

  .footer-container .footer-contact-detail .contact-detail-icon {
    padding: 0.75rem; /* 12px */
    width: 3rem; /* 48px */
  }

  .footer-container .footer-contact-detail .contact-detail-text {
    font-size: 0.75rem; /* 12 */
  }

  .footer-container .footer-mobile-app-image-container .icon {
    width: 9rem; /* 128px */
  }

  .advert-mapbox-section-wrapper .footer-mobile-app-image-container {
    transform: translateY(15px);
  }

  .footer-container .advert-mapbox-section-wrapper .mobile-app-links-container {
    padding: 1rem; /* 16px */
    width: 80%;
  }

  .footer-container .social-icon-wrapper {
    width: 2.5rem; /* 40px */
    height: 2.5rem; /* 40px */
  }

  .footer-container .download-buttons-container .download-button {
    padding: 0.75rem; /* 12px */
  }

  .footer-container .advert-mapbox-section-wrapper {
    align-items: center;
    gap: 1rem; /* 16px */
    margin-top: 2.5rem; /* 40px */
  }
}

@media screen and (max-width: 1200px) {
  .footer-container .advert-mapbox-section-wrapper {
    flex-direction: column;
    height: fit-content;
  }

  .footer-container .advert-mapbox-section-wrapper .mobile-app-links-container {
    width: 100%;
    height: 180px;
    padding: 3rem;
  }

  .advert-mapbox-section-wrapper .footer-mobile-app-image-container {
    transform: translateY(12px);
  }

  .footer-container .advert-mapbox-section-wrapper .mapbox-wrapper {
    width: 100%;
  }

  .footer-container .contact-info-wrapper {
    justify-content: space-around;
    gap: 2rem; /* 32px */
    align-items: center;
  }

  .footer-container .contact-info-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 50%;
    gap: 1rem; /* 16px */
  }
}

@media only screen and (max-width: 1100px) {
  .footer-contact-detail .contact-detail-text {
    font-size: 0.825rem;
  }
}

@media screen and (min-width: 430px) and (max-width: 1000px) {
  .footer-container .copyright-section-wrapper {
    display: block;
    text-align: center;
  }

  .footer-container .copyright-section-text {
    padding: 0.5rem;
  }

  .footer-container .contact-info-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-container .social-icons-box-container {
    flex-direction: row;
    gap: 1.5rem;
    position: relative;
    margin: auto;
  }
  .footer-container .contact-info-container {
    width: 100%;
  }

  .footer-container .advert-mapbox-section-wrapper .description-text {
    text-align: center;
  }

  .footer-container .footer-mobile-app-image-container .icon {
    display: none;
  }

  .footer-container .download-buttons-container {
    flex-direction: column;
    gap: 1rem;
  }

  .footer-container .advert-mapbox-section-wrapper .mobile-app-links-container {
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
    height: fit-content;
    background-image: url("../../.././images/footer-image.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    background-blend-mode: overlay;
  }

  .footer-container .footer-ozak-logo {
    width: 15vw;
  }
}

@media screen and (max-width: 430px) {
  .footer-wrapper,
  .footer-container {
    padding: 1rem;
  }

  .footer-container .copyright-section-wrapper {
    display: block;
    padding: 0.75rem 0;
  }

  .footer-container .footer-contact-detail .contact-detail-icon {
    border: none;
    padding: 0;
    width: 1.5rem;
  }

  .footer-container .footer-contact-detail .contact-detail-text,
  .footer-container .copyright-section-text {
    font-size: 0.75rem;
    text-align: start;
  }

  .footer-container .contact-info-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-container .social-icons-box-container {
    flex-direction: row;
    gap: 1.5rem;
    position: relative;
    margin: auto;
  }
  .footer-container .contact-info-container {
    width: 100%;
  }

  .footer-container .advert-mapbox-section-wrapper .description-text {
    text-align: center;
  }

  .footer-container .footer-mobile-app-image-container .icon {
    display: none;
  }

  .footer-container .download-buttons-container {
    flex-direction: column;
    gap: 1rem;
  }

  .footer-container .advert-mapbox-section-wrapper .mobile-app-links-container {
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
    height: fit-content;
    background-image: url("../../.././images/footer-image.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    background-blend-mode: overlay;
  }

  .footer-container .footer-ozak-logo {
    width: 15vw;
  }
}
