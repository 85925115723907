@font-face {
  font-family: "Euclid Circular B";
  src: local("Euclid-Circular-B"),
    url("./fonts/Euclid-Circular-B-Regular.ttf") format("truetype");
  font-weight: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Euclid Circular B", sans-serif;
}

:root {
  --primary-color: #e7a707;
  --primary-color-rgb: 231, 167, 7;
  --decrease-color-rgb: 234, 57, 67;
  --increase-color-rgb: 22, 199, 132;

  --light-mode-primary-bg: #ffffff;
  --light-mode-secondary-bg: #f0efea;
  --light-mode-main-bg: #fafafa;
  --light-mode-main-text: #2c2a2a;
  --light-mode-sub-text: #435f75;

  --dark-mode-primary-bg: #2c3244;
  --dark-mode-secondary-bg: #e5e5e5;
  --dark-mode-main-bg: #1a1c29;
  --dark-mode-main-text: #ffffff;
  --dark-mode-sub-text: #858a9c;

  --primary-color-rgb: 231, 167, 7;
  --decrease-rgb: 234, 57, 67;
  --increase-rgb: 22, 199, 132;
  --light-mode-secondary-bg-rgb: 240, 239, 234;
  --light-mode-main-bg-rgb: 250, 250, 250;

  --light-mode-main-text-rgb: 44, 42, 42;
  --light-mode-sub-text-rgb: 67, 95, 117;
  --dark-mode-primary-bg-rgb: 44, 50, 68;
  --dark-mode-secondary-bg-rgb: 229, 229, 229;
  --dark-mode-main-bg-rgb: 26, 28, 41;
  --dark-mode-sub-text-rgb: 133, 138, 156;

  --font-family-primary: "Euclid Circular B";
  --font-family-secondary: "Segoe UI"; /*used for the time on the mockup */
}
