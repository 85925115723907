.nav-slider-wrapper {
  max-width: 100%;
  height: 3.5rem; /* 64px */
  background: var(--light-mode-secondary-bg);
  display: flex;
  align-items: center;
}

.dark-mode .nav-slider-wrapper {
  background-color: var(--dark-mode-main-bg);
}

.nav-slider-wrapper .nav-scroll-slider .marquee {
  display: flex;
  overflow: hidden;
  gap: 2rem; /* 32px */
}
