.view-switcher-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--light-mode-main-bg);
  border-radius: 16px;
  padding: 8px;
}

.dark-mode .view-switcher-container {
  color: var(--dark-mode-sub-text);
  background-color: var(--dark-mode-main-bg);
}

.view-switcher-container .switcher-tab {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;
  border-radius: 16px;
  margin: 0;
  cursor: pointer;
  min-width: fit-content;
}

.view-switcher-container .switcher-tab.is-active {
  color: var(--light-mode-primary-bg);
  background: var(--primary-color);
}

.view-switcher-container.page-switcher {
  background: none;
}

.view-switcher-container.page-switcher .switcher-tab.is-active {
  color: var(--primary-color);
  background: rgba(var(--primary-color-rgb), 0.1);
}

.view-switcher-container-dropdown {
  color: var(--light-mode-main-text);
  border-radius: 16px;
}

.page-switcher-dropdown {
  border: none;
  text-align: center;
  display: none;
}

.dark-mode .view-switcher-container-dropdown {
  background-color: var(--dark-mode-main-bg);
  color: var(--dark-mode-main-text);
}
