.App {
  background: var(--light-mode-main-bg);
}

.app-frame {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: 100%;
  max-width: 1920px;
}

.price-trackers-wrapper {
  display: grid;
  justify-content: center;
  grid-template-columns: 50% 50%;
  gap: 30px;
  padding: 30px 50px;
  margin-top: 145px;
  width: 100%;
}

.footer-wrapper {
  padding: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 1920px;
}
/* dark theme */
.dark-mode {
  background-color: var(--dark-mode-primary-bg);
  color: var(--dark-mode-main-text);
}

@media screen and (max-width: 1600px) {
  .price-trackers-wrapper {
    font-size: 0.75rem; /* 12px */
  }
}

@media screen and (max-width: 1200px) {
  .price-trackers-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 35px;
  }
}
.dark-mode .app-frame {
  background-color: var(--dark-mode-main-bg);
}

@media screen and (max-width: 430px) {
  .price-trackers-wrapper {
    padding: 1rem;
  }
}
