.price-tracker-body {
  display: flex;
  margin-top: 9.5rem; /* 152px */
  width: 100vw;
  padding: 2rem;
  gap: 2rem;
}

/* Option styling */
.option-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: var(--light-mode-sub-text);
  gap: 0.625rem; /* 10px */
  padding: 2rem; /* 32px */
}

.option-icon {
  gap: 1rem; /* 16px '*/
}

.option-format {
  display: flex;
  align-items: center;
  gap: 1rem; /* 16px */
}

.option-title {
  color: var(--light-mode-main-text);
  font-size: 1.125rem; /* 18px */
}

.dark-mode .option-title {
  color: var(--dark-mode-main-text);
}

.option-subtitle {
  font-size: 0.875rem; /* 14px */
}

.dark-mode .option-subtitle {
  color: var(--dark-mode-sub-text);
}

.option-url {
  color: var(--primary-color);
  font-weight: bold;
}
.option-url:hover {
  cursor: pointer;
}

@media screen and (max-width: 1440px) {
  /* typograhy */
  .option-subtitle {
    font-size: 0.688rem; /* 11px */
  }
}

@media screen and (max-width: 1024px) {
  .option-subtitle {
    font-size: 0.75rem; /* 12px */
  }

  .option-icon {
    width: 2.5rem; /* 40px */
  }
}

@media screen and (max-width: 992px) {
  .price-tracker-body {
    flex-direction: column;
  }

  .price-tracker-container {
    padding: 1rem; /* 16px */
  }

  .option-subtitle {
    display: none;
  }
  .option-icon {
    width: 2rem; /* 32px */
  }

  .option-container > .view-switcher-container,
  .option-title {
    font-size: 1rem; /* 16px */
  }
}

@media screen and (max-width: 600px) {
  .option-icon {
    display: none;
  }

  .option-container > .view-switcher-container,
  .option-title {
    font-size: 0.875rem; /* 14px */
  }
}

@media screen and (max-width: 500px) {
  .option-container > .view-switcher-container,
  .option-title {
    font-size: 0.75rem; /* 12px */
  }
}

@media screen and (max-width: 430px) {
  .option-container {
    padding: 0.5rem 0;
  }

  .option-container > .view-switcher-container,
  .option-title {
    font-size: 0.625rem; /* 10px */
  }
}
