.nav-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
  background: var(--light-mode-primary-bg);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.nav-wrapper .navbar-container {
  max-width: 1920px;
  height: 96px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.navbar-container .ozak-logo {
  width: 120px;
  cursor: pointer;
  margin-right: 15px;
}

.navbar-container .nav-items-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.navbar-container .nav-items-wrapper .nav-items-group-left,
.navbar-container .nav-items-wrapper .nav-items-group-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-mode .nav-items-group-left .select,
.dark-mode .nav-items-group-left .search-input,
.dark-mode .nav-items-group-right {
  color: var(--dark-mode-main-text);
}

.navbar-container .nav-items-wrapper .nav-items-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.navbar-container .nav-items-container .nav-input-container {
  padding: 8px;
  border-radius: 16px;
  border: 1px solid rgba(var(--light-mode-sub-text-rgb), 0.2);
  width: max-content;
  margin: 0 5px;
}

.navbar-container .nav-items-container .label {
  color: rgba(var(--light-mode-sub-text-rgb), 1);
  font-size: 10px;
  display: block;
}

.dark-mode .navbar-container .nav-items-container .label {
  color: var(--dark-mode-sub-text);
}

.navbar-container .nav-items-container .nav-input-container .search-container,
.navbar-container
  .nav-items-container
  .nav-input-container
  .search-container
  .search-input {
  width: 50px;
  border: 0;
  outline: none;
}

.navbar-container
  .nav-items-container
  .nav-input-container
  .dropdown-container
  select {
  border: 0;
  outline: none;
  cursor: pointer;
  width: 100%;

  overflow-x: hidden;
}

.navbar-container
  .nav-items-container
  .nav-input-container
  .dropdown-container
  .altin-option:hover {
  background-color: #000;
  color: var(--light-mode-primary-bg);
}

.navbar-container .nav-items-container .price-container {
  padding: 8px;
  border-radius: 16px;
  background: rgba(var(--light-mode-sub-text-rgb), 0.05);
  margin: 0 5px;
}

.navbar-container .nav-items-wrapper .theme-icon {
  width: 50px;
  cursor: pointer;
}

.navbar-container .nav-items-wrapper .date-time-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  width: 195px;
}

.navbar-container .nav-items-wrapper .date-time-container .date-container,
.navbar-container .nav-items-wrapper .date-time-container .time-container {
  display: flex;
  align-items: center;
}
.navbar-container .nav-items-wrapper .date-time-container .time-container {
  width: 80px;
}

.navbar-container .nav-items-wrapper .date-time-container .calendar-icon,
.navbar-container .nav-items-wrapper .date-time-container .clock-icon {
  width: 20px;
  margin-right: 5px;
}

.select,
.search-input {
  background: none;
}

@media screen and (max-width: 1600px) {
  .navbar-container {
    font-size: 0.75rem; /* 12px */
  }

  .navbar-container .nav-items-wrapper::-webkit-scrollbar {
    display: none;
  }

  .navbar-container .nav-items-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .navbar-container .ozak-logo {
    width: 5rem; /* 80 */
  }

  .navbar-container .nav-items-wrapper .theme-icon {
    width: 2rem; /* 32px */
  }
}

@media screen and (max-width: 1500px) {
  .navbar-container .ozak-logo {
    width: 4rem; /* 64px */
  }
}

@media screen and (max-width: 1200px) {
  .navbar-container .nav-items-group-right .page-switcher,
  .nav-items-group-left .view-switcher-container {
    display: none;
  }

  .navbar-container .nav-items-group-right .page-switcher-dropdown,
  .navbar-container .nav-items-group-left .page-switcher-dropdown {
    display: initial;
  }

  .nav-items-group-right .view-switcher-container-dropdown {
    border: none;
    background: none;
    margin: 0 0.5rem; /* 0, 8px */
  }

  .nav-items-group-left .view-switcher-container-dropdown {
    border: none;
    padding: 1rem 0.5rem; /* 16px, 8px */
    text-align: center;
  }
}

/* Phones and Handhelds */
@media screen and (max-width: 415px) {
  .navbar-container,
  .nav-input-container .select,
  .nav-input-container .search-input,
  .navbar-container .view-switcher-container-dropdown {
    font-size: 0.625rem;
  }

  .nav-wrapper {
    max-width: 100vw;
  }
  .nav-wrapper .navbar-container {
    display: block;
  }

  .navbar-container .nav-items-group-left {
    font-size: 0.625rem;
  }

  .navbar-container .ozak-logo {
    margin: 0;
    padding: 0.5rem;
  }
  .navbar-container .nav-items-wrapper {
    overflow-x: scroll;
  }
}

/* Tablet devices  */
@media screen and (min-width: 415px) and (max-width: 1000px) {
  .nav-wrapper .navbar-container {
    display: block;
  }

  .navbar-container .nav-items-wrapper {
    overflow-x: scroll;
  }

  .navbar-container .ozak-logo {
    margin: 0;
    padding: 0.5rem;
  }
}
