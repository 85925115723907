.nav-slider-price-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-slider-price-box .nav-slider-currency-pair {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* 8px */
}

.nav-slider-currency-pair .nav-slider-pair-text {
  font-family: "Euclid Circular B";
  font-weight: 400;
  font-size: 0.625rem; /* 10px */
  line-height: 0.875rem; /* 14px */
  color: var(-light-mode-main-text);
}

.nav-slider-price-box .nav-slider-price {
  font-family: var(--font-family-primary);
  font-weight: 600;
  font-size: 1.25rem; /* 20px */
  line-height: 1.5rem; /* 24px */
  color: var(--light-mode-sub-text);
}

.dark-mode .nav-slider-price-box .nav-slider-price {
  color: var(--dark-mode-sub-text);
}

.nav-slider-currency-pair .nav-slider-arrow-down {
  width: 0;
  height: 0;
  border-left: 0.375rem solid transparent; /* 7px */
  border-right: 0.375rem solid transparent;
  border-top: 0.375rem solid rgb(var(--decrease-color-rgb));
}

.nav-slider-currency-pair .nav-slider-arrow-up {
  width: 0;
  height: 0;
  border-left: 0.375rem solid transparent; /* 7px */
  border-right: 0.375rem solid transparent;
  border-bottom: 0.375rem solid rgb(var(--increase-color-rgb));
}

@media screen and (max-width: 1600px) {
  .nav-slider-price-box .nav-slider-price {
    font-size: 1rem; /* 16px */
  }
}

@media screen and (max-width: 1500px) {
  .nav-slider-price-box .nav-slider-price {
    font-size: 0.875rem; /* 14px */
  }
}
