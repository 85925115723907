.price-tracker-container {
  border-radius: 1rem; /* 16px */
  overflow: hidden;
  width: 100%;
  background-color: var(--light-mode-primary-bg);
  height: fit-content;
}

@media screen and (max-width: 415px) {
  .price-tracker-container {
    padding: 0.5rem;
  }
}
